.mapboxgl-map {
  border-radius: .25rem 0 0 0.25rem;
}

.map-marker {
  font-size: 2em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}